import { DefaultTicketsOrdering, Direction, FilterPresetType, FilterPresetView, SortBy } from '@eeedo/types';
import { pick } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Icon } from 'semantic-ui-react';

import type { FilterPresetPayload } from '@eeedo/types';
import type { FC } from 'react';

import FilterPresetsModal from '../Management/FilterPresets/FilterPresetsModal';
import { setActiveFilterPreset } from 'src/actions/filterPresetsActions';
import { setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';

import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';

type FilterPresetsWidgetProps = {
  clearFilters: () => void;
};

const FilterPresetsWidget: FC<FilterPresetsWidgetProps> = ({ clearFilters }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPayload, setModalPayload] = useState<Record<string, any> | FilterPresetPayload>({});
  const [editId, setEditId] = useState<number | null>(null);

  const {
    filters,
    sorting,
    direction,
    id: activeTabId
  } = useSelector((state: State) => {
    const isInfopage = state.router.location.pathname.startsWith('/infopage');
    const tabs: MenuTab[] = isInfopage ? [...state.infoPageListTabs.values()] : Object.values(state.ticketListTabs);
    return tabs.find((t) => t.activeTab)!;
  });

  const activeFilterPresetId = useSelector((state: State) => state.filterPresets.activeId);

  const isCurrentUserAdmin = useSelector((state: State) =>
    ['ROL1', 'ROL10'].includes(state.usersList.usersList.find((u) => u.UID === state.userData.UID)?.role.id ?? '')
  );
  const defaultFilterPreset = useSelector((state: State) => state.userData.userPreferences.defaultFilterPreset);
  const currentView = useSelector((state: State) =>
    state.router.location.pathname.startsWith('/infopage') ? FilterPresetView.infopage : FilterPresetView.main
  );

  // Filters out those the user does not have access to
  const filterPresets = useSelector((state: State) =>
    state.filterPresets.filterPresets.filter(
      (filterPreset) =>
        filterPreset.ticketTypes.length === 0 ||
        filterPreset.ticketTypes.some((filterSetTicketType) => state.userData.ticketTypes.includes(filterSetTicketType))
    )
  );

  const mobileMode = useSelector((state: State) => state.mobileReducer.mobileMode);

  const sortedFilterPresets = useMemo(
    () =>
      [...filterPresets].sort((f1, f2) => {
        if (f1.id === defaultFilterPreset || f2.id === defaultFilterPreset) {
          return f1.id === defaultFilterPreset ? -1 : 1;
        }

        if (f1.isFavourite && f2.isFavourite) {
          return f2.name > f1.name ? -1 : 1;
        } else if (f1.isFavourite || f2.isFavourite) {
          return f1.isFavourite ? -1 : 1;
        }

        return f2.name > f1.name ? -1 : 1;
      }),
    [filterPresets, defaultFilterPreset]
  );

  const isFilterOn =
    !Object.keys(filters as any).every((k) => !(filters as any)[k]) ||
    (sorting && sorting !== DefaultTicketsOrdering[1]) ||
    (direction && direction !== DefaultTicketsOrdering[0]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFilterOn) {
      dispatch(setActiveFilterPreset({ filterPresetId: null }));
    }
  }, [isFilterOn]);

  const applyFilter = useCallback(
    (id: number) => {
      const filter = sortedFilterPresets.find((f) => f.id === id);
      if (!filter) {
        throw new Error('Something went wrong');
      }

      if (!activeTabId) {
        return;
      }
      const setFilter = currentView === FilterPresetView.infopage ? setInfopagelistFilter : setTicketlistFilter;
      const setSorting = currentView === FilterPresetView.infopage ? () => null : setTicketlistSorting;

      dispatch(setActiveFilterPreset({ filterPresetId: filter.id }));
      clearFilters();
      Object.keys(filter?.filters).forEach((filterKey) => {
        dispatch(
          setFilter({
            id: activeTabId,
            value: filter?.filters[filterKey],
            parameter: filterKey as any
          })
        );
      });

      dispatch(setSorting(filter.sorting as any, filter.direction));
    },
    [dispatch, sortedFilterPresets, activeTabId, clearFilters]
  );

  const initOverwriteCurrent = useCallback(() => {
    const preset = sortedFilterPresets.find((p) => p.id === activeFilterPresetId);
    if (!preset) {
      throw new Error('Something went wrong');
    }
    setModalPayload(
      pick(
        {
          ...preset,
          direction: direction ?? Direction[0],
          filters: filters ? { ...filters } : {},
          sorting: sorting ?? SortBy.dueDate,
          isDefault: defaultFilterPreset === preset.id
        },
        [
          'name',
          'filters',
          'sorting',
          'direction',
          'isFavourite',
          'isActive',
          'type',
          'ticketTypes',
          'view',
          'isDefault'
        ]
      )
    );
    setEditId(activeFilterPresetId);
    setIsModalOpen(true);
  }, [
    setModalPayload,
    setIsModalOpen,
    setEditId,
    sortedFilterPresets,
    activeFilterPresetId,
    direction,
    filters,
    sorting
  ]);

  const initiateSaveAsNew = useCallback(() => {
    setModalPayload({
      direction: direction ?? Direction[0],
      filters: filters ?? {},
      sorting: sorting ?? SortBy.dueDate
    });
    setIsModalOpen(true);
  }, [setModalPayload, setIsModalOpen, direction, filters, sorting]);

  return (
    <div style={{ margin: '5px 0 5px 10px' }}>
      <Dropdown
        trigger={
          <Button icon basic color={activeFilterPresetId ? 'red' : undefined}>
            <Icon name="bars" />
          </Button>
        }
        icon={false}
        floating
        labeled
        direction={mobileMode ? 'left' : 'right'}
      >
        <Dropdown.Menu style={{ zIndex: 9999999 }}>
          <Dropdown.Header>{t('filter_presets.dropdown_title')}</Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Menu scrolling>
            {sortedFilterPresets.length > 0 ? (
              sortedFilterPresets.map((preset) => (
                <Dropdown.Item
                  selected={preset.id === activeFilterPresetId}
                  onClick={() => applyFilter(preset.id)}
                  style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
                >
                  <div>{preset.name}</div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {preset.id === defaultFilterPreset || preset.isFavourite ? (
                      <Icon
                        name="star"
                        color={preset.id === defaultFilterPreset ? 'red' : 'blue'}
                        style={{ marginLeft: '20px' }}
                      />
                    ) : null}
                    <div style={{ marginLeft: '6px' }}>
                      {preset.type === FilterPresetType.global ? (
                        <Icon name="world" color="black" />
                      ) : (
                        <Icon name="user" color="black" />
                      )}
                    </div>
                  </div>
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled>{t('filter_presets.dropdown_no_filters')}</Dropdown.Item>
            )}
          </Dropdown.Menu>
          <Dropdown.Divider />
          <Dropdown.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              disabled={
                !isFilterOn ||
                Boolean(
                  activeFilterPresetId &&
                    sortedFilterPresets.find((p) => p.id === activeFilterPresetId)?.type === FilterPresetType.global &&
                    !isCurrentUserAdmin
                )
              }
              labelPosition="left"
              color={activeFilterPresetId ? 'red' : 'blue'}
              onClick={() => {
                activeFilterPresetId ? initOverwriteCurrent() : initiateSaveAsNew();
              }}
              icon
            >
              <Icon name="save" />
              {activeFilterPresetId
                ? t('filter_presets.dropdown_button_overwrite')
                : t('filter_presets.dropdown_button_save_as_new')}
            </Button>
          </Dropdown.Header>
        </Dropdown.Menu>
      </Dropdown>
      <FilterPresetsModal
        modalPayload={modalPayload as FilterPresetPayload}
        editId={editId}
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
          setEditId(null);
          setModalPayload({});
        }}
        setSelectedPreset={(filterPresetId: number | null) => dispatch(setActiveFilterPreset({ filterPresetId }))}
        currentView={currentView}
      />
    </div>
  );
};

export default FilterPresetsWidget;
