export interface DueDateThreshold {
  min: number;
  max: number;
  color: string;
}

export interface FieldSet extends Record<string, any> {
  id: string;
  name: string;
  displayName: string;
  propertyName?: string;
  group: string;
  entityTypes?: string[];
  createEntity?: string;
  openByDefault?: boolean;
  customerInfo?: Field[];
  caseInfo?: Field[];
  additionalFieldSets: FieldSet[];
  customSearch?: string;
  postAttachmentOperation?: boolean;
  searchHelp?: any;
  valueExists?: boolean;
  fieldset?: string;
}

export enum FieldTypes {
  action = 'action',
  complexList = 'complexList',
  datepicker = 'datepicker',
  daterange = 'daterange',
  dateselector = 'dateselector',
  email = 'email',
  empty = 'empty',
  errorMessage = 'errorMessage',
  googleMaps = 'googleMaps',
  link = 'link',
  list = 'list',
  loadingChevron = 'loadingChevron',
  phoneNumber = 'phoneNumber',
  separator = 'separator',
  subAccordion = 'subAccordion',
  subEntity = 'subEntity',
  subEntityField = 'subEntityField',
  textarea = 'textarea',
  verifyFields = 'verifyFields'
}

export interface FieldOption {
  name: string;
  value: string;
  style?: Record<string, unknown>;
  icon?: string;
}

export interface FieldParams extends Record<string, any> {
  unit?: any;
  input?: any;
  noMongo?: boolean;
  readOnly?: boolean;
  noSearch?: boolean;
  actionType?: string;
  entityType?: string;
  getByValue?: string;
  formNoRender?: boolean;
  clickToSearch?: boolean;
  mongoNoRender?: boolean;
  replaceString?: string[];
  replaceStrings?: string[][];
  includeToSearch?: boolean;
  openInfoCheckbox?: boolean;
  integrationNoRender?: boolean;
  maxVisibleAccordionItems?: number;
  mandatoryForTicketClosing?: boolean;
  // can be just hardcoded string or pattern in a format of parseFieldValue utility function
  optionsURL?: string;
  // array of fields that are going to be cleared on current field change
  dependentFields?: string[];
  clearable?: boolean;
  selection?: any;
}

export interface Field {
  name?: string;
  displayName?: string;
  title?: string;
  customType?: FieldTypes;
  value: string;
  options?: FieldOption[] | string;
  displayField?: string;
  props?: any;
  switch?: any[];
  object?: string;
  params?: FieldParams;
  childPrefix?: any;
  childTitle?: string;
  readOnly?: boolean;
  searchable?: boolean;
  linkText?: string;
  subField?: Field;
  selectableFields?: string[];
  minSelected?: number;
  logAutomaticComment?: boolean;
  openInIFrame?: boolean;
  iFrameTabTitle?: string;
  mustBeVerifiedIn?: { channels: number[] };
  validation?: {
    regex: string;
    flags?: string;
    errorText: string;
  };
}

export interface SenderEmail {
  value: string;
  text: string;
  email: string;
  default?: boolean;
  type?: any;
}

export type WidgetName =
  | 'Metadata'
  | 'Priority'
  | 'CaseChannel'
  | 'HandleStatus'
  | 'AttachEntityById'
  | 'CustomerInfo'
  | 'FieldSets'
  | 'Delegations'
  | 'Transcriptions'
  | 'TicketRelations'
  | 'Tags'
  | 'ExternalLinks'
  | 'Attachments'
  | 'Surveys'
  | 'Archive'
  | 'EIdentification'
  | 'SuggestedArticles'
  | 'LinkLists'
  | 'ChatGPT'
  | 'AssetsMeta'
  | 'AssetsUsage'
  | 'RescheduleCallback';

export interface WidgetOptions extends WidgetTagsOptions {
  minLength?: number;
  maxLength?: number;
  values?: { text: string; value: string | number }[];
}

export interface WidgetTagsOptions {
  categoryIds: number[];
}

export interface Widget {
  name: WidgetName;
  displayName?: string;
  defaultClosed: boolean;
  itemsClosed?: boolean;
  mandatoryForTicketClosing?: boolean;
  valueExists?: boolean | null;
  type?: string; // This apparently exists according to one usage in Case.tsx
  showOnlyRoles?: number[];
  show?: boolean;
  options?: WidgetOptions;
}

export interface EntityRoute {
  ticketType: number;
  entityType: string;
  displayName: string;
  contactRoute: boolean;
  useAsOutboundContact: boolean;
  readonly: boolean;
  addingSupported: boolean;
}

export interface TicketType {
  _id: string;
  id: number;
  name: string;
  entityEventsEnabled?: boolean;
  senderemail: SenderEmail;
  additionalSenderEmails?: SenderEmail[];
  widgetOrder: Widget[] | string[];
  serviceName: string;
  dueDateThresholds?: DueDateThreshold[];
  fieldSets: FieldSet[];
  secureMailTitlePrefix?: string;
  secureMailTitlePostfix?: string;
  secureMailAddressMailPostfix?: string;
  OCoutboundServiceName?: any;
  knowledgeBank?: {
    portalId?: number;
  };
  allowed?: boolean;
  userId?: string;
  entityRouting?: EntityRoute[];
  canAdd?: boolean;
  senderSMSNumbers?: {
    text: string;
    value: string;
  }[];
  workingHours?: {
    name: string;
    description: string;
  };
  defaultFieldSet?: string;
  openInfoCheckbox?: {
    threshold: number;
    fieldValue: string;
  };
}

export interface TicketTypeMetadata {
  _id: string;
  id: number;
  name: string;
  allowed?: boolean;
  canAdd?: boolean;
}
