import { getI18n } from 'react-i18next';

import type { UserWithProfile } from '@eeedo/types';

import { type Attachment } from '../types/Ticket';
import { parseDirective } from './parsers';
import { parseContent } from './parseUtils';
import { sanitizeHTML } from './sanitize';

import type { Comment } from '../types/Ticket';
import type { ExtraOptions } from './sanitize';

const TRANS_MAP: { [key: string]: { t: string; addParam?: boolean; parseContentToUserName?: boolean } } = {
  ORIGINAL_PHONENUMBER: { t: 'AUTOMATIC_COMMENT_ORIGINAL_PHONENUMBER', addParam: true },
  CALL_RESPONSE_TIME: { t: 'AUTOMATIC_COMMENT_CALL_RESPONSE_TIME', addParam: true },
  USER_CALLED_TO: { t: 'USER_CALLED_TO_NUMBER', addParam: true },
  MISSING_CONFIGURATION: { t: 'MISSING_CONFIGURATION' },
  BOT_ESCALATED_THE_CONVERSATION: { t: 'BOT_ESCALATED_THE_CONVERSATION' },
  SENT_SMS_TO: { t: 'AUTOMATIC_COMMENT_SENT_SMS_TO', addParam: true },
  TICKET_CREATED_VIA_FIS_API: { t: 'TICKET_CREATED_VIA_FIS_API' },
  CUSTOMER_CLOSED_CHAT: { t: 'AUTOMATIC_COMMENT_CUSTOMER_CLOSED_CHAT' },
  AGENT_CLOSED_CHAT: { t: 'AUTOMATIC_COMMENT_AGENT_CLOSED_CHAT' },
  TICKET_PATCHED_BY_CS_INTEGRATOR: { t: 'TICKET_PATCHED_BY_CS_INTEGRATOR' },
  TICKET_CREATED_VIA_FORM_API: { t: 'TICKET_CREATED_VIA_FORM_API' },
  BUNDLED_CHILD_TICKETS: { t: 'BUNDLED_CHILD_TICKETS' },
  COMMENT_ATTACHED_PREVIOUSLY_SERVED_CUSTOMER: { t: 'COMMENT_ATTACHED_PREVIOUSLY_SERVED_CUSTOMER' },
  COMMENT_NO_ENTITY_ATTACHED_EMAIL: { t: 'COMMENT_NO_ENTITY_ATTACHED_EMAIL' },
  COMMENT_NO_ENTITY_ATTACHED_PHONE: { t: 'COMMENT_NO_ENTITY_ATTACHED_PHONE' },
  HAD_MULTIPLE_CUSTOMERS: { t: 'AUTOMATIC_COMMENT_HAD_MULTIPLE_CUSTOMERS' },
  HAD_MULTIPLE_ENTITIES: { t: 'AUTOMATIC_COMMENT_HAD_MULTIPLE_CUSTOMERS' },
  INCLUDED_ATTACHMENT: { t: 'AUTOMATIC_COMMENT_RECEIVED_ATTACHMENT' },
  WRITTEN_BY_BOT: { t: 'AUTOMATIC_COMMENT_WRITTEN_BY_BOT' },
  SEND_TRANSFERRING_FROM_TO: { t: 'AUTOMATIC_COMMENT_SEND_TRANSFERRING_FROM_TO', addParam: true },
  ACCEPT_TRANSFERRING: { t: 'AUTOMATIC_COMMENT_ACCEPT_TRANSFERRING', addParam: true },
  RECEIVE_TRANSFERRING_FROM_TO: { t: 'AUTOMATIC_COMMENT_RECEIVE_TRANSFERRING_FROM_TO', addParam: true },
  CHAT_WAS_CLOSED_AUTOMATICALLY: { t: 'AUTOMATIC_COMMENT_CHAT_WAS_CLOSED_AUTOMATICALLY' },
  USER_CLOSED_CHAT: { t: 'AUTOMATIC_COMMENT_USER_CLOSED_CHAT', parseContentToUserName: true },
  BOT_PROCESSED_THE_COMMENT_WITH_NO_CHANGES: { t: 'AUTOMATIC_COMMENT_BOT_PROCESSED_THE_COMMENT_WITH_NO_CHANGES' },
  BOT_PROCESSED_THE_COMMENT: { t: 'AUTOMATIC_COMMENT_BOT_PROCESSED_THE_COMMENT' },
  OPEN_EXTERNAL_LINK: { t: 'AUTOMATIC_COMMENT_OPEN_EXTERNAL_LINK' }
};

export const translateContent = (content: string, type: Comment['type'], usersData: UserWithProfile[]): string => {
  // translate directives
  const directive = parseDirective(content);
  if (directive.name) {
    const trans = TRANS_MAP[directive.name];
    if (trans) {
      let text: string = getI18n().t(trans.t);
      if (trans.parseContentToUserName) {
        const userData = usersData.find((user) => {
          return user.UID.substring(3) === content.substr(directive.length + 1).trim();
        });
        let userName = getI18n().t('UNKNOWN_USER');
        if (userData) {
          userName = `${userData.profile.firstName || ''} ${userData.profile.lastName}`;
        }
        return text + ': ' + userName;
      } else if (trans.addParam && directive.param) {
        text = `${text} ${directive.param}`;
        return text + content.substring(directive.length);
      } else {
        return text + content.substring(directive.length);
      }
    }
  }
  // old sanitation code for non-translated text
  if (type === 'survey') {
    // Remove links from survey comments
    content = content.replace(/<a\b[^>]*>(.*?)<\/a>/gi, '').replace(/(?:https?):\/\/[\n\S]+/gi, '');
  }
  return content;
};

const sanitizationSettings: ExtraOptions = {
  addAllowedTags: ['a'],
  addAllowedAttributes: { a: ['href', 'name', 'target'] }
};

export const parseComments = (comments: Comment[], users: UserWithProfile[], attachments: Attachment[]): Comment[] => {
  return comments.map((comment) => {
    const { metaData, content, type } = comment;
    const source = metaData?.html ?? content ?? '';
    const nToBr = !metaData?.html;

    const sanitizedContent = sanitizeHTML(
      translateContent(parseContent(source, attachments, nToBr), type, users),
      sanitizationSettings
    );

    return {
      ...comment,
      parsedContent: sanitizedContent
    };
  });
};
