export enum ContentNamingType {
  String = 'string',
  Number = 'number'
}

export const convertPrefixStringToNumber = (value: string | number, prefix?: string): number => {
  if (typeof value === 'number') {
    return value;
  }

  if (prefix) {
    return parseInt(value.replace(prefix, ''), 10);
  }

  return parseInt(value.substring(3), 10);
};

export const convertTicketId = <T extends number | string>(taskId: string | number, nameType: ContentNamingType): T => {
  switch (nameType) {
    case ContentNamingType.String:
      if (`${taskId}`.startsWith('TSK') || `${taskId}`.startsWith('INF')) {
        return taskId as T;
      } else {
        return `TSK${taskId}` as T;
      }
    case ContentNamingType.Number:
      return parseInt(`${taskId}`.replace(/(TSK)|(INF)/, ''), 10) as T;
    default:
      return taskId as T;
  }
};

export const envVarToNumber = (value: string | undefined, defaultValue: number) => {
  const numericValue = Number(value);

  return isNaN(numericValue) ? defaultValue : numericValue;
};
