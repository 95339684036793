import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Dropdown, Icon } from 'semantic-ui-react';

import type { PersonalData } from '@eeedo/types';

import type { TicketArchivalUploadData } from 'src/types/Archival';
import type { Template } from 'src/types/Template';
import type { Attachment, Ticket } from 'src/types/Ticket';

interface ArchivalProps {
  templates: Array<Template>;
  ticket: Ticket;
  userData: PersonalData;
  submitArchival: (sendData: TicketArchivalUploadData) => void;
  fetchArchivalTemplates: () => void;
}

interface ArchivalState {
  selectedTemplate: string | undefined;
  selectedAttachmentIds: string[];
}

class Archival extends React.Component<ArchivalProps, ArchivalState> {
  constructor(props: ArchivalProps) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount = () => {
    this.props.fetchArchivalTemplates();
  };

  private getInitialState = () => {
    const state: ArchivalState = {
      selectedTemplate: undefined,
      selectedAttachmentIds: []
    };
    return state;
  };

  submitArchival = () => {
    if (this.state.selectedTemplate !== undefined) {
      const sendData = {
        userId: this.props.userData.UID,
        selectedTemplate: this.state.selectedTemplate,
        selectedAttachmentIds: this.state.selectedAttachmentIds,
        ticketId: this.props.ticket.id.substring(3)
      };
      this.props.submitArchival(sendData);
    } else {
      // cannot submit, UI wont alarm this but its built to make it obvious that you have to pick a template
    }
  };

  handleTemplatePick = (template: string) => {
    this.setState({ selectedTemplate: template });
  };

  handlePickAttachment = (attachmentdIds: string[]) => {
    this.setState({ selectedAttachmentIds: attachmentdIds });
  };

  render() {
    const templateChoices = this.props.templates.map((aTemplate) => {
      return {
        text: aTemplate.name,
        value: aTemplate.name
      };
    });

    const attachmentChoices = this.props.ticket.attachments.map((attachment: Attachment) => {
      return {
        text: attachment.fileName,
        value: attachment.id
      };
    });

    return (
      <Translation ns="translations">
        {(t) => (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                marginBottom: '8px'
              }}
            >
              <Dropdown
                additionLabel={t('ADD')}
                selection={true}
                selectOnBlur={false}
                selectOnNavigation={false}
                search={true}
                size="mini"
                value={this.state.selectedTemplate || ''}
                id="templatesDropdown"
                onChange={(event, data) => {
                  if (data.value !== undefined) {
                    this.handleTemplatePick(data.value.toString());
                  }
                }}
                placeholder={t('CASE_ARCHIVAL_PICK_ARCHIVAL_TEMPLATE')}
                options={templateChoices}
                allowAdditions={false}
              />
            </div>
            <Dropdown
              style={{ marginBottom: '8px' }}
              selection={true}
              selectOnBlur={false}
              fluid={true}
              search={true}
              multiple={true}
              value={this.state.selectedAttachmentIds}
              onChange={(event, data) => {
                const value = data.value as string[];
                const addedAttachments = this.props.ticket.attachments
                  .filter((attachment) => value.includes(attachment.id))
                  .map((attachment) => attachment.id);

                this.handlePickAttachment(addedAttachments);
              }}
              noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
              placeholder={t('ADD_COMMENT_ATTACHMENT_PLACEHOLDER')}
              options={attachmentChoices}
              description="ctrl + o"
            />
            <div
              style={{
                display: 'flex',
                marginBottom: '2px',
                justifyContent: 'right'
              }}
            >
              {/* will be re-enabled once this feature gets support */}
              {/* <div style={{
                display: "flex"
              }}>
                <b style={{
                  marginTop: "9px",
                  marginRight: "6px"
                }}>{t("CASE_ARCHIVAL_LATEST_ARCHIVAL") + ": "}</b>
                <Input
                  disabled={true}
                  style={{
                    maxWidth: "115px"
                  }}
                  value={" placeholder"}
                />
              </div> */}
              <Button
                className="sendToArchiveButton"
                icon
                labelPosition="left"
                disabled={this.state.selectedTemplate === undefined}
                onClick={this.submitArchival}
                primary
                style={{
                  marginLeft: '0px',
                  float: 'right'
                }}
              >
                <Icon name="plus" />
                {t('CASE_ARCHIVAL_SEND_TO_ARCHIVE')}
              </Button>
            </div>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default Archival;
